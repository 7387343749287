'use client'

import AddressFieldToggle from '../formParts/AddressFieldToggle'
import CodonationBox from './CodonationBox'
import PlatformFormDonationAmountSum from './PlatformFormDonationAmountSum'

import AddressFields from '@/donationForm/_dependencies/components/DonationForm/formParts/AddressFields'
import AmountAndIntervalSelection from '@/donationForm/_dependencies/components/DonationForm/formParts/AmountAndIntervalSelection'
import CompanyFields from '@/donationForm/_dependencies/components/DonationForm/formParts/CompanyFields'
import Debug from '@/donationForm/_dependencies/components/DonationForm/formParts/Debug'
import DonationFormCaptcha from '@/donationForm/_dependencies/components/DonationForm/formParts/DonationFormCaptcha'
import GeneralError from '@/donationForm/_dependencies/components/DonationForm/formParts/GeneralError'
import HeroImage from '@/donationForm/_dependencies/components/DonationForm/formParts/HeroImage'
import HolderFields from '@/donationForm/_dependencies/components/DonationForm/formParts/HolderFields'
import HolderHeadline from '@/donationForm/_dependencies/components/DonationForm/formParts/HolderHeadline'
import HolderTitle from '../formParts/HolderTitle'
import MatchingEventNotice from '@/donationForm/_dependencies/components/DonationForm/formParts/MatchingEventNotice'
import MessageField from '@/donationForm/_dependencies/components/DonationForm/formParts/MessageField'
import OfflineMessage from '@/donationForm/_dependencies/components/DonationForm/formParts/OfflineMessage'
import PaymentMethodError from '@/donationForm/_dependencies/components/DonationForm/formParts/PaymentMethodError'
import PaymentMethodSelection from '@/donationForm/_dependencies/components/DonationForm/formParts/PaymentMethodSelection'
import PlatformNewsletterCheckbox from '@/donationForm/_dependencies/components/DonationForm/formParts/PlatformNewsletterCheckbox'
import ProhibitedNotice from '@/donationForm/_dependencies/components/DonationForm/formParts/ProhibitedNotice'
import StripeCCFields from '@/donationForm/_dependencies/components/DonationForm/formParts/StripeFields/StripeCCFields'
import StripeSEPADebitFields from '@/donationForm/_dependencies/components/DonationForm/formParts/StripeFields/StripeSEPADebitFields'
import SubmitButton from '@/donationForm/_dependencies/components/DonationForm/formParts/SubmitButton'
import Terms from '@/donationForm/_dependencies/components/DonationForm/formParts/Terms'
import TrustLogos from '@/donationForm/_dependencies/components/DonationForm/formParts/TrustLogos'
import YoutubeFields from '../formParts/YoutubeFields/YoutubeFields'
import classNames from 'classnames'
import styles from './PlatformForm.module.css'
import { LayoutStack } from '@betterplace/design-system/client'
import { useDonationFormValues } from '@/donationForm/_dependencies/helpers'
import type { DonationFormProps } from '@/donationForm/types'

function WrappingDivs({ children }: { children?: React.ReactNode }) {
  return (
    // This class is necessary for the autosuggest selector
    <div className="platform-donations-new">
      <div className={styles.donationFormBackground}>
        <div className={classNames(styles.container, styles.transparentHeaderSpace)}>
          <div className={styles.row}>
            <div className={styles.wrapper}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

function PlatformForm({ config, initialFormValues, initialFFs, ...props }: DonationFormProps) {
  const { donationsProhibited, receiverName, receiverProfilePicture, receiverUrl, matchingEvent, showCodonation } =
    config
  const [companyDonation] = useDonationFormValues(['_company_donation'])
  if (donationsProhibited) {
    return (
      <WrappingDivs>
        <HeroImage
          receiverName={receiverName}
          receiverProfilePicture={receiverProfilePicture}
          receiverUrl={receiverUrl}
          linked
        />
        <ProhibitedNotice backHref={receiverUrl} />
      </WrappingDivs>
    )
  }

  return (
    <>
      <WrappingDivs>
        <HeroImage
          receiverName={receiverName}
          receiverProfilePicture={receiverProfilePicture}
          receiverUrl={receiverUrl}
          linked
        />
        {matchingEvent && (
          <div className={styles.donationFormNotices}>
            <MatchingEventNotice matchingEvent={matchingEvent} />
          </div>
        )}

        <form {...props}>
          <div className={styles.donationFormContent}>
            <GeneralError />
            <OfflineMessage />
            <AmountAndIntervalSelection />
            <div className={styles.formFieldsContainer}>
              <LayoutStack space="50" className={styles.blockWrapper}>
                <PaymentMethodSelection />
                <StripeCCFields />
                <StripeSEPADebitFields />
                <PaymentMethodError />
              </LayoutStack>

              <LayoutStack space="400" className={styles.blockWrapper}>
                <HolderHeadline />
                <HolderFields />
                {!companyDonation && (
                  <>
                    <MessageField />
                    <YoutubeFields />
                  </>
                )}
              </LayoutStack>

              {companyDonation && (
                <LayoutStack className={styles.blockWrapper} space="400">
                  <HolderTitle />
                  <LayoutStack space="400">
                    <CompanyFields />
                    <AddressFields required />
                    <MessageField />
                    <YoutubeFields />
                  </LayoutStack>
                </LayoutStack>
              )}

              {!companyDonation && (
                <LayoutStack className={styles.blockWrapper} space="200">
                  <AddressFieldToggle>
                    <AddressFields required />
                  </AddressFieldToggle>
                </LayoutStack>
              )}

              <CodonationBox />
              {showCodonation && <PlatformFormDonationAmountSum />}
              <PlatformNewsletterCheckbox />
              <SubmitButton />
              <Terms />
              <TrustLogos />
              <DonationFormCaptcha />
            </div>
          </div>
        </form>
        <Debug initialFFs={initialFFs} initialFormValues={initialFormValues} />
      </WrappingDivs>
    </>
  )
}

export default PlatformForm
